import './second_page.css'
import {animated, config, useSpring, useTransition} from "react-spring";
import {useEffect, useState} from "react";
import icon1 from '../../images/icon1.svg'
import icon2 from '../../images/icon2.svg'
import icon3 from '../../images/icon3.svg'
import icon4 from '../../images/icon4.svg'

import websiteIcon from '../../images/Website.svg'
import emailIcon from '../../images/Email.svg'
import whatsupIcon from '../../images/Whatsap.svg'
import telegramIcon from '../../images/Telega.svg'

const SecondPage = ({profile_info, links, phone, photo, vcard}) => {

    useEffect(()=>{
        document.title = `${profile_info.first_name} ${profile_info.last_name}`
    },[profile_info.first_name, profile_info.last_name])

    const [nextPage, setNextPage] = useState(false)

    const animatedStylePhoto = useSpring({
        from: {
            opacity: 0,
            height: nextPage ? '0': '60vh'
        },
        to: {
            opacity: 1,
            height: '60vh'
        },
        config: {
            tension: 70,
            mass: 10,
            duration: nextPage ? 500:200
        },
        delay: nextPage ? 2000:0,
        reverse: nextPage,
        onRest: ()=>setNextPage(true)
    })

    const animatedStyleText1 = useSpring({
        from: {
            transform: 'translateX(-100%)'
        },
        to: {
            transform: 'translateX(0)'
        },
        delay: 500
    })

    const animatedStyleText2 = useSpring({
        from: {
            transform: 'translateX(-100%)'
        },
        to: {
            transform: 'translateX(0)'
        },
        delay: 600
    })

    const animatedStyleText3 = useSpring({
        from: {
            transform: 'translateX(-100%)'
        },
        to: {
            transform: 'translateX(0)'
        },
        delay: 650
    })

    const animatedStyleIconsBlock = useSpring({
        from: {
            height: '0px',
            marginTop: '0px'
        },
        to: {
            height: '210px',
            marginTop: '43px'
        },
        config: {
            duration: 250
        },
        delay: 2600
    })

    const animatedStyleIcon1 = useSpring({
        from: {
            transform: 'scale(0)'
        },
        to: {
            transform: 'scale(1)'
        },
        delay: 3000
    })

    const animatedStyleIcon2 = useSpring({
        from: {
            transform: 'scale(0)'
        },
        to: {
            transform: 'scale(1)'
        },
        delay: 3250
    })

    const animatedStyleIcon3 = useSpring({
        from: {
            transform: 'scale(0)'
        },
        to: {
            transform: 'scale(1)'
        },
        delay: 3500
    })

    const animatedStyleIcon4 = useSpring({
        from: {
            transform: 'scale(0)'
        },
        to: {
            transform: 'scale(1)'
        },
        delay: 3750
    })

    return (
        <div className={'SecondPage'}>
            <div className={'second-container'}>
                <animated.div style={{...animatedStylePhoto, backgroundImage: `url(${photo})`}} className="img-holder"/>

                <animated.div style={animatedStyleIconsBlock} className="icons-block">
                    <div className="row">
                        <animated.img style={animatedStyleIcon1} src={icon1} alt="icon1"/>
                        <animated.img style={animatedStyleIcon2} src={icon2} alt="icon2"/>
                    </div>
                    <div className="row">
                        <animated.img style={animatedStyleIcon3} src={icon3} alt="icon3"/>
                        <animated.img style={animatedStyleIcon4} src={icon4} alt="icon4"/>
                    </div>
                </animated.div>

                <animated.div className="text-block">
                    <animated.h1 style={animatedStyleText1}>{profile_info.first_name}</animated.h1>
                    <animated.h1 style={animatedStyleText2}><span>{profile_info.last_name}</span></animated.h1>
                    <animated.h3 style={animatedStyleText3}>{profile_info.company_position}<br/>{profile_info.company_name}</animated.h3>
                </animated.div>

                <SocialBlock links={links}/>

                <ButtonsBlock phone={phone} vcard={vcard}/>

            </div>
        </div>
    )
}


const SocialBlock = ({links}) => {

    const [state, setState] = useState(false)

    const transitions = useTransition(state, {
        from: { opacity: 0 },
        enter: { opacity: 1 },
        leave: { opacity: 0 },
        delay: 2000,
        config: {
            duration: 1,
        },
        onRest: ()=>setState(true)
    })

    const animationSite = useSpring({
        from: {
            transform: 'scale(0)'
        },
        to: {
            transform: 'scale(1)'
        },
        config: {
            ...config.slow,
            velocity: 0.07
        },
        delay: 4250
    })

    const animationEmail = useSpring({
        from: {
            transform: 'scale(0)'
        },
        to: {
            transform: 'scale(1)'
        },
        config: {
            ...config.slow,
            velocity: 0.07
        },
        delay: 4500
    })

    const animationWhatsup = useSpring({
        from: {
            transform: 'scale(0)'
        },
        to: {
            transform: 'scale(1)'
        },
        config: {
            ...config.slow,
            velocity: 0.07
        },
        delay: 4750
    })

    const animationTelegram = useSpring({
        from: {
            transform: 'scale(0)'
        },
        to: {
            transform: 'scale(1)'
        },
        config: {
            ...config.slow,
            velocity: 0.07
        },
        delay: 5000
    })


    return transitions( (styles, item) => item && (
            <animated.div style={styles} className="social">
                <animated.a style={animationSite} href={links.website}>
                    <img src={websiteIcon} alt="facebookIcon"/>
                </animated.a>
                <animated.a style={animationEmail} href={links.email}>
                    <img src={emailIcon} alt="instagramIcon"/>
                </animated.a>
                <animated.a style={animationWhatsup} href={links.whatsup}>
                    <img src={whatsupIcon} alt="whatsupIcon"/>
                </animated.a>
                <animated.a style={animationTelegram} href={links.telegram}>
                    <img src={telegramIcon} alt="telegramIcon"/>
                </animated.a>
            </animated.div>
        )
    )
}


const ButtonsBlock = ({phone, vcard}) => {


    const [state, setState] = useState(false)
    const transitions = useTransition(state, {
        from: { opacity: 0 },
        enter: { opacity: 1 },
        leave: { opacity: 0 },
        delay: 5000,
        config: {
            duration: 1,
        },
        onRest: ()=>setState(true)
    })

    const animationTel = useSpring({
        from:{
            transform: 'scale(0)'
        },
        to: {
            transform: 'scale(1)'
        },
        delay: 5250
    })
    const animationDownload = useSpring({
        from:{
            transform: 'scale(0)'
        },
        to: {
            transform: 'scale(1)'
        },
        delay: 5500
    })

    return transitions((styles, item) => item && (
        <div className="buttons">
            <animated.a style={animationTel} href={`tel:${phone}`}>Позвонить</animated.a>
            <animated.a style={animationDownload} href={vcard} download>Добавить в контакты</animated.a>
        </div>
    ))

}

export default SecondPage