import './init_page.css'
import {animated, config, useSpring} from 'react-spring'
import {useState} from "react"
import logoImage from '../../images/logo.svg'

const InitPage = () => {
    return (
        <div className={'InitPage'}>
            <div className="logo-container">
                <LogoSvg/>
                <BoxSvg/>
            </div>
        </div>
    )
}

const LogoSvg = () => {
    const animatedStyle = useSpring({
        from: {
            transform: 'scale(0) translateY(-50%)'
        },
        to: {
            transform: 'scale(1) translateY(-50%)'
        },
        config: {
            ...config.slow,
            velocity: 0.07
        },
        delay: 500
    })

    return (
        <animated.img
            src={logoImage} alt={'logo'}
            style={animatedStyle}
        />
    )
}

const BoxSvg = () => {
    const [strokeLength, setStrokeLength] = useState(0)

    const animatedStyle = useSpring({
        from: {
            strokeDashoffset: strokeLength
        },
        to: {
            strokeDashoffset: 0
        },
        config: {
            mass: 1,
            friction: 50,
            restVelocity: 1
        },
        delay: 1000
    })

    return (
        <svg
            viewBox="0 0 180 130"
        >
            <animated.polyline
                ref={ref=>{if (ref) {setStrokeLength(ref.getTotalLength())}}}
                style={animatedStyle}
                strokeDasharray={strokeLength}
                points="1.5 16.95 1.5 1.5 178.5 1.5 178.5 127.94 1.5 127.94 1.5 112.99"
                fill={"none"}
                stroke={"#FBA91B"}
                strokeWidth={"3px"}
            />
        </svg>
    )
}

export default InitPage