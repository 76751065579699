import InitPage from "./pages/InitPage/InitPage";
import SecondPage from "./pages/SecondPage/SecondPage";
import {animated, useTransition} from "react-spring";
import {useState} from "react";
import {BrowserRouter, Routes, Route} from "react-router-dom";
import bt_photo from './images/bt_photo.jpg'
import bt_vcard from './vcards/bt.vcf'
import vb_photo from './images/vb_photo.jpg'
import vb_vcard from './vcards/vb.vcf'

function App() {
    const [state, setState] = useState(true)

    const transitions = useTransition(state, {
        from: {opacity: 1},
        enter: {opacity: state ? 0:1},
        leave: {opacity: 0},
        config: {
            tension: 150,
            mass: 2,
            clamp: true
        },
        delay: state ? 2250:0,
        onRest: () => setState(false)
    })

    const bt_phone = '89998302050'
    const vb_phone = '89167421554'

    const bt_links = {
        website: 'https://www.smseng.ru',
        email: 'mailto:bt@smseng.ru',
        whatsup: 'https://wa.me/+79998302050',
        telegram: 'https://telegram.im/@Temirov_BM'
    }
    const vb_links = {
        website: 'https://www.smseng.ru',
        email: 'mailto:vb@smseng.ru',
        whatsup: 'https://wa.me/+79167421554',
        telegram: 'https://telegram.im/@Basov_VN'
    }

    const bt_info = {
        first_name: 'Баходур',
        last_name: 'Темиров',
        company_name: 'ООО «СМС-Инжиниринг»',
        company_position: 'Генеральный директор'
    }
    const vb_info = {
        first_name: 'Басов',
        last_name: 'Вадим',
        company_name: 'ООО «СМС-Инжиниринг»',
        company_position: 'Технический директор'
    }


    return transitions(({opacity},item) => item ?
        (<animated.div style={{opacity: opacity.to({ range: [0.0, 1.0], output: [0, 1] })}}>
            <InitPage/>
        </animated.div>) :
        (<BrowserRouter>
            <Routes>
                <Route exact path="/bt" element={
                    <animated.div>
                        <SecondPage
                            profile_info={bt_info}
                            phone={bt_phone}
                            links={bt_links}
                            photo={bt_photo}
                            vcard={bt_vcard}
                        />
                    </animated.div>
                }/>
                <Route exact path="/vb" element={
                    <animated.div>
                        <SecondPage
                            profile_info={vb_info}
                            phone={vb_phone}
                            links={vb_links}
                            photo={vb_photo}
                            vcard={vb_vcard}
                        />
                    </animated.div>
                }/>
            </Routes>
        </BrowserRouter>)
    )
}


export default App
